export default class FullscreenController {
  constructor(element){
    this.element = element
  }

  get enabled() {
    return document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;
  }

  toggle() {
    if (this.enabled) {
      this.disable()
    } else {
      this.enable()
    }
  }

  enable() {
    const { element } = this
    let enabled = true;
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    } else if (element.msRequestFullscreen){
      element.msRequestFullscreen()
    } else {
      enabled = false
    }

    if (enabled && typeof this.onEnable === "function") {
      this.onEnable()
    }
  }

  disable() {
    let disabled = true;
    if (document.exitFullscreen){
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else {
      disabled = false
    }

    if (disabled && typeof this.onDisable === "function") {
      this.onDisable()
    }
  }
}