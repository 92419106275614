

import { library } from '@fortawesome/fontawesome-svg-core'


import { faCalendarAlt as fasCalendarAlt, faChartBar as fasChartBar, faCheck as fasCheck, faCog as fasCog, faHome as fasHome, faNewspaper as fasNewspaper, faPencilAlt as fasPencilAlt, faTrash as fasTrash, faUser as fasUser, faUsers as fasUsers, faRoute as fasRoute, faStopwatch as fasStopwatch, faList as fasList, faPlus as fasPlus, faExclamationTriangle as fasExclamationTriangle, faCamera as fasCamera, faInfoCircle as fasInfoCircle, faMale as fasMale, faFemale as fasFemale, faArrowLeft as fasArrowLeft, faBold as fasBold, faItalic as fasItalic, faStrikethrough as fasStrikethrough, faLink as fasLink, faHeading as fasHeading, faQuoteRight as fasQuoteRight, faCode as fasCode, faListUl as fasListUl, faListOl as fasListOl, faIndent as fasIndent, faOutdent as fasOutdent, faUndo as fasUndo, faRedo as fasRedo } from "@fortawesome/free-solid-svg-icons"


import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons"



export function setup() {
  
  library.add(fasCalendarAlt);
  
  library.add(fasChartBar);
  
  library.add(fasCheck);
  
  library.add(fasCog);
  
  library.add(fasHome);
  
  library.add(fasNewspaper);
  
  library.add(fasPencilAlt);
  
  library.add(fasTrash);
  
  library.add(fasUser);
  
  library.add(fasUsers);
  
  library.add(fasRoute);
  
  library.add(fasStopwatch);
  
  library.add(fasList);
  
  library.add(fasPlus);
  
  library.add(fasExclamationTriangle);
  
  library.add(fasCamera);
  
  library.add(fasInfoCircle);
  
  library.add(fasMale);
  
  library.add(fasFemale);
  
  library.add(fasArrowLeft);
  
  library.add(fasBold);
  
  library.add(fasItalic);
  
  library.add(fasStrikethrough);
  
  library.add(fasLink);
  
  library.add(fasHeading);
  
  library.add(fasQuoteRight);
  
  library.add(fasCode);
  
  library.add(fasListUl);
  
  library.add(fasListOl);
  
  library.add(fasIndent);
  
  library.add(fasOutdent);
  
  library.add(fasUndo);
  
  library.add(fasRedo);
  
  library.add(farHeart);
  
}
