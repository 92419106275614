import { Controller } from 'stimulus'
import $ from 'jquery'

export default class ImageInputController extends Controller {
  static targets = ["imageTag", "input", "previewTag"]

  connect() {
    this._hideImageTagIfSourceIsBlank()
  }

  inputChanged() {
    this._loadImagePreviewForInput()
  }

  _hideImageTagIfSourceIsBlank() {
    if (this.imageTagTarget.src == "") {
      $(this.imageTagTarget).addClass("d-none")
    }
  }

  _loadImagePreviewForInput() {
    const input = this.inputTarget

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => this._showImagePreview(e.target.result)
      reader.readAsDataURL(input.files[0]);
    }
  }

  _showImagePreview(dataUrl) {
    if (/data:image\//.test(dataUrl)) {
      const $img = $(this.imageTagTarget)
      const $previewTag = $(this.previewTagTarget)

      $previewTag.removeClass("d-none")
      $img.attr("src", dataUrl).removeClass("d-none")
    }
  }

}