import { Controller } from 'stimulus'

import moment from "moment"

export default class DatetimePickerController extends Controller {

  static targets = ["input"]

  connect() {
    this._readOptions()
    this._showFormattedCurrentValue()
  }

  _readOptions() {
    this.showDates = this.data.get("date") == "true"
    this.showTimes = this.data.get("time") == "true"
  }

  _showFormattedCurrentValue() {
    const currentValue = this.data.get("value")
    const parsedMoment = moment(currentValue)

    this.inputTarget.value = parsedMoment.format(this._plainTextDateFormat())
  }

  _plainTextDateFormat() {
    const format = []

    if (this.showDates) {
      format.push("L")
    }

    if (this.showTimes) {
      format.push("LT")
    }

    return format.join(" ")
  }
}