import { Controller } from 'stimulus'
import { FullscreenController } from "../utils"

export default class RankingTableController extends Controller {
  static targets = ["table", "toolbar"];

  connect() {
    this.fullscreenController = new FullscreenController(this.element)
    this.fullscreenController.onEnable  = () => this.fullscreenEnabled()
    this.fullscreenController.onDisable = () => this.fullscreenDisabled()

    this.isFullscreenEnabled = false
  }

  disconnect() {
    if (this.fullscreenController.enabled) {
      this.fullscreenController.disable()
    }
  }

  toggleFullscreen() {
    this.fullscreenController.toggle()
  }

  disableFullscreen() {
    this.fullscreenController.disable()
  }

  // =======================
  // = Fullscreen Handling =
  // =======================

  fullscreenChangeTrackerTick() {
    if (this.fullscreenController.enabled && !this.isFullscreenEnabled) {
      this.fullscreenEnabled()
    } else if (!this.fullscreenController.enabled && this.isFullscreenEnabled) {
      this.fullscreenDisabled()
    }
  }

  fullscreenEnabled() {
    this.isFullscreenEnabled = true

    this.element.classList.add("fullscreen-element")

    this.toolbarTarget.classList.add("p-3")
    this.toolbarTarget.classList.add("position-fixed")
    this.toolbarTarget.classList.add("bg-light")
    this.toolbarTarget.classList.add("shadow-sm")
    this.toolbarTarget.classList.add("w-100")

    this.tableTarget.style.marginTop = `${this.toolbarTarget.clientHeight + 10}px`

    this.checkInterval = setInterval(() => this.fullscreenChangeTrackerTick(), 2000)
  }

  fullscreenDisabled() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval)
    }

    this.isFullscreenEnabled = false

    this.element.classList.remove("fullscreen-element")
    this.tableTarget.style.marginTop = "";

    this.toolbarTarget.classList.remove("p-3")
    this.toolbarTarget.classList.remove("position-fixed")
    this.toolbarTarget.classList.remove("bg-light")
    this.toolbarTarget.classList.remove("shadow-sm")
    this.toolbarTarget.classList.remove("w-100")
  }

}